import { StepStatus } from "@allica/ui-react";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { AlertStage, Stage } from "../../BusinessRewards.types";
import { FormProvider, useForm } from "react-hook-form";
import CompanyUI from "src/shared/company/CompanyUI";
import {
  CompanyErrorFields,
  CompanyFormValues,
  CompanySectionType,
} from "src/shared/company/Company.types";
import { getBackendDataStructure } from "src/shared/company/Company.utils";

import { defaultApplicantSectionData } from "src/shared/applicant/Applicant.utils";
import { DepositsAPI } from "src/core/service";
import { useEffect } from "react";
import { ApplicationStatusResponse } from "src/core/service/deposits-api/DepositsApi.types";

const BusinessRewardsCompany = () => {
  const methods = useForm<CompanyFormValues>({
    mode: "onChange",
    defaultValues: {
      addresses: [],
      expectedAnnualTurnover: "",
      taxResidencies: [],
    },
  });

  const {
    stepperConfig,
    businessRewardsData,
    setBusinessRewardsData,
    setCurrentStage,
    setShowGenericError,
    updateStepperConfig,
    setAlertStages,
    alertStages,
  } = useBusinessRewardsContext();

  const {
    response: savedResponse,
    status: saveReqStatus,
    request: saveData,
    error: saveError,
  } = DepositsAPI<ApplicationStatusResponse, CompanyErrorFields>(
    `applications/businesses/${businessRewardsData?.applicationID}/company`,
  );

  useEffect(() => {
    if (saveReqStatus.success) {
      const oldCompanyHouseNumber =
        businessRewardsData?.businessRewardsApplicationSections?.companySection?.companyHouseNumber;
      const newData = { ...businessRewardsData };
      const values = methods?.getValues();

      const stepperStages = [{ stage: Stage.BUSINESS, value: { status: StepStatus.COMPLETE } }];

      const newCompanyHouseNumber = values?.companyHouseNumber;
      const companyHasChanged =
        !!oldCompanyHouseNumber && newCompanyHouseNumber !== oldCompanyHouseNumber;

      if (companyHasChanged) {
        const newAlertStages: AlertStage[] = [];
        newData.businessRewardsApplicationSections.applicantSection = defaultApplicantSectionData;

        if (stepperConfig.applicant.status === StepStatus.COMPLETE) {
          stepperStages.push({ stage: Stage.APPLICANT, value: { status: StepStatus.ALERT } });
          newAlertStages.push(Stage.APPLICANT);
        } else if (stepperConfig.applicant.status === StepStatus.INACTIVE)
          stepperStages.push({ stage: Stage.APPLICANT, value: { status: StepStatus.INCOMPLETE } });

        if (newAlertStages.length) setAlertStages([...alertStages, ...newAlertStages]);
      } else if (stepperConfig.applicant.status === StepStatus.INACTIVE) {
        stepperStages.push({ stage: Stage.APPLICANT, value: { status: StepStatus.INCOMPLETE } });
      }

      newData.cobApplicationId = savedResponse?.cobApplicationId || "";
      newData.businessRewardsApplicationSections.companySection = {
        ...newData?.businessRewardsApplicationSections.companySection,
        ...getBackendDataStructure(values),
      } as CompanySectionType;
      setBusinessRewardsData(newData);

      setCurrentStage(Stage.APPLICANT);
      updateStepperConfig(stepperStages, companyHasChanged);
    }
  }, [saveReqStatus.success]);


  return (
    <FormProvider {...methods}>
      <CompanyUI
        saveData={saveData}
        saveError={saveError}
        companySectionData={businessRewardsData?.businessRewardsApplicationSections?.companySection}
        saveReqStatus={saveReqStatus}
        setShowGenericError={setShowGenericError}
        stepperConfig={stepperConfig}
      />
    </FormProvider>
  );
};

export default BusinessRewardsCompany;
