import {
  Button,
  ConfirmIcon,
  ErrorIcon,
  getHeadingStyle,
  Heading,
  Modal as AllicaModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  WarningIcon,
} from "@allica/ui-react";
import { ComponentWithAs, Flex, IconProps, useBreakpointValue } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { ModalProps, ModalVariant } from "./Modal.types";

export function Modal({
  isOpen,
  onClose,
  headerTitle,
  eyebrowText,
  primaryButtonText,
  primaryAction,
  secondaryButtonText,
  secondaryAction,
  variant,
  closeOnOverlayClick = true,
  primaryButtonLoading = false,
  secondaryButtonLoading = false,
  newHeadingStyle = false,
  children,
}: PropsWithChildren<ModalProps>) {
  const icons: {
    [key in Exclude<ModalVariant, "default">]: {
      icon: ComponentWithAs<"svg", IconProps>;
      color: string;
    };
  } = {
    alert: { icon: WarningIcon, color: "mango.600" },
    error: { icon: ErrorIcon, color: "$danger-01" },
    success: { icon: ConfirmIcon, color: "$success-01" },
  };

  const headingStyle = useBreakpointValue({
    base: getHeadingStyle("heading-04"),
    sm: getHeadingStyle("heading-03"),
  });

  const Icon = variant && icons[variant].icon;
  return (
    <>
      <AllicaModal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size="4xl"
        autoFocus={false}
        closeOnOverlayClick={closeOnOverlayClick}
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ base: "31.2rem", sm: "56.8rem" }}
          maxH="89%"
          p="3.2rem"
          pt="2.4rem"
          sx={{ zIndex: 999999 }}
          borderRadius="0.8rem"
        >
          <ModalHeader p="0" fontWeight="normal">
            {Icon && <Icon color={icons[variant].color} boxSize="4.4rem" p="0.2rem" mb="1.6rem" />}
            {eyebrowText && (
              <Text as="p" color="$text-03" textStyle="body-03">
                {eyebrowText}
              </Text>
            )}
            {newHeadingStyle ? (
              <Heading color="$text-01" size={{ base: "h4", sm: "h3" }} as="h2">
                {headerTitle}
              </Heading>
            ) : (
              <Heading sx={headingStyle} color="$text-01">
                {headerTitle}
              </Heading>
            )}
          </ModalHeader>
          <ModalCloseButton
            size="xl"
            sx={{ boxSize: "3.2rem", right: "1.2rem", top: "1.2rem", width: "3.2rem" }}
          />
          <ModalBody p="0" mt="3.2rem" overflowY="auto" overflowX="hidden">
            {children}
          </ModalBody>
          <ModalFooter p="0" mt="3.2rem">
            <Flex
              w="100%"
              direction={{ base: "column-reverse", sm: "row" }}
              justifyContent={{ base: "center", sm: "flex-end" }}
              alignItems="center"
            >
              {secondaryButtonText && (
                <Button
                  isLoading={secondaryButtonLoading}
                  loadingText={secondaryButtonText}
                  spinnerPlacement="end"
                  variant="ghost"
                  minW={{ base: "24.8rem", sm: "2.5rem" }}
                  mr={{ base: 0, sm: "1.2rem" }}
                  mt={{ base: "1.2rem", sm: "0" }}
                  onClick={secondaryAction || onClose}
                  padding="2.4rem 3.2rem"
                >
                  {secondaryButtonText}
                </Button>
              )}
              <Button
                isLoading={primaryButtonLoading}
                loadingText={primaryButtonText}
                spinnerPlacement="end"
                minW={{ base: "24.8rem", sm: "2.5rem" }}
                onClick={primaryAction}
                padding="2.4rem 3.2rem"
              >
                {primaryButtonText}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </AllicaModal>
    </>
  );
}
