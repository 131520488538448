import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import { ThemeProvider } from "@allica/ui-react";
import environment from "./environments/environment";
import App from "./core/app/App";

import "./styles/index.css";

if (environment.googleTagManager.enabled)
  TagManager.initialize(environment.googleTagManager.config);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
