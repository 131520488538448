import { Button, PlusIcon } from "@allica/ui-react";
import { Box, Select } from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { FieldValues, useFieldArray, useFormContext } from "react-hook-form";
import { FormGroup } from "src/components/input-set/FormGroup";
import { useStore } from "src/core/store/StoreContext";
import AddNationality from "./add-nationality/AddNationality";
import { NationalityProps } from "./Nationality.types";
import { getFilteredOptions, getProperty, moveBritishToFirstPosition } from "src/core/utils";

const Nationality = <FormType extends FieldValues>({
  showAddNationality,
  setShowAddNationality,
  fieldRootPath = "",
}: NationalityProps<FormType>) => {
  const referenceData = useStore();
  const nationalities = moveBritishToFirstPosition(referenceData?.nationality);
  const [changedNationality, setChangedNationality] = useState("");
  const [nationalitiesOptions, setNationalitiesOptions] = useState(nationalities);

  const rootPath = fieldRootPath ? `${fieldRootPath}.` : "";

  const {
    register,
    control,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: `${rootPath}nationalities`,
    control,
  });

  const handleAddClick = (nationalityNumber: number) => {
    const currentNationality = getValues(`${rootPath}nationalities.${nationalityNumber}.value`);
    if (currentNationality) {
      append({ value: "" });
      if (!showAddNationality) {
        setShowAddNationality(true);
      }
    } else {
      setError(`${rootPath}nationalities.${nationalityNumber}.value`, {
        message: "Please select a nationality",
      });
    }
  };

  const handleDeleteClick = (nationalityNumber: number) => {
    remove(nationalityNumber);
    setChangedNationality("");
  };

  const handleNationalityChange = (e: Event, nationalityNumber: number) => {
    clearErrors(`${rootPath}nationalities.${nationalityNumber}`);
    const { value } = e?.target as HTMLInputElement;
    setChangedNationality(value);
  };

  useEffect(() => {
    if (fields.length >= 1) {
      const data = getValues();
      const currentNationalityCode = getValues(`${rootPath}nationalities.0.value`);
      const usedNationalities = getProperty(data, fieldRootPath).nationalities?.map(
        (item: { value: string }) => item.value,
      );
      const filteredNationalities = getFilteredOptions(
        currentNationalityCode,
        usedNationalities,
        nationalities,
      );
      setNationalitiesOptions(filteredNationalities);
    }
  }, [changedNationality, fields]);

  useEffect(() => {
    setNationalitiesOptions(nationalities);
  }, [referenceData.nationality]);

  useEffect(() => {
    if (fields.length === 1) {
      setShowAddNationality(false);
    }
  }, [fields]);

  return (
    <Box>
      {fields?.map((item, index) => (
        <Fragment key={item.id}>
          {index === 0 ? (
            <>
              <FormGroup
                label="Nationality"
                isRequired
                key={item.id}
                error={getProperty(errors, fieldRootPath)?.nationalities?.[index]?.value?.message}
              >
                <Select
                  {...register(`${rootPath}nationalities.${index}.value`, {
                    required: "Please select a nationality",
                    onChange: (e) => handleNationalityChange(e, index),
                  })}
                  placeholder="Please select"
                >
                  {nationalitiesOptions?.map((item) => (
                    <option key={item?.name} value={item?.name}>
                      {item?.description}
                    </option>
                  ))}
                </Select>
              </FormGroup>
              {!showAddNationality && (
                <Box mt="3.2rem">
                  <Button
                    variant={"text"}
                    leftIcon={<PlusIcon />}
                    onClick={() => handleAddClick(index)}
                    iconSpacing="0.8rem"
                  >
                    Add second nationality
                  </Button>
                </Box>
              )}
            </>
          ) : (
            showAddNationality && (
              <AddNationality<FormType>
                nationalityNumber={index}
                handleAddClick={handleAddClick}
                handleDeleteClick={handleDeleteClick}
                handleNationalityChange={handleNationalityChange}
                changedNationality={changedNationality}
                allNationalities={fields}
                fieldRootPath={fieldRootPath}
              />
            )
          )}
        </Fragment>
      ))}
    </Box>
  );
};

export default Nationality;
