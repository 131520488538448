import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Box, Link, useDisclosure } from "@chakra-ui/react";
import { Heading, StepStatus, Text } from "@allica/ui-react";

import environment from "src/environments/environment";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { appInsights } from "src/core/app/ApplicationInsights";
import { Modal } from "../../../../components/modal/Modal";
import { PersonalSavingApiType, Stage } from "../../PersonalSavings.types";
import { DepositsAPI } from "src/core/service";
import { usePersonalContext } from "../../context/PersonalContext";
import { useStore } from "src/core/store/StoreContext";
import { handleSignUpError } from "src/shared/sign-up/SignUp.utils";
import { signUpSchema } from "src/shared/sign-up/SignUp.validation";
import { SignUpFormValues } from "src/shared/sign-up/SignUp.types";
import {
  SignUpErrorFields,
  SignUpResponseType,
} from "src/core/service/deposits-api/DepositsApi.types";
import { SignUpForm } from "src/shared/sign-up/SignUpForm";
import { Overlay } from "src/components/overlay/Overlay";
import { getCookie } from "../../../../core/utils/cookies/cookies";

export const PersonalSignUp = () => {
  const {
    stepperConfig,
    personalSavingData,
    setPersonalSavingData,
    setCurrentStage,
    setStepperConfig,
    setShowGenericError,
    selectedProductInfo,
  } = usePersonalContext();

  const { storeStatus } = useStore();

  const formMethods = useForm<SignUpFormValues>({
    resolver: joiResolver(signUpSchema),
  });

  const { setError, getValues, reset } = formMethods;

  const { isOpen: showModal, onOpen: openModal, onClose: closeModal } = useDisclosure();

  const {
    response: saveResponse,
    status: saveReqStatus,
    request: saveData,
    error: saveError,
  } = DepositsAPI<SignUpResponseType, SignUpErrorFields>("applications/signup");

  const navigateToOB = () => {
    window.location.replace(environment.ui.onlineBanking);
  };

  const onSubmit: SubmitHandler<SignUpFormValues> = (values) => {
    setShowGenericError(false);
    const { emailAddress, phoneNumber, password } = values;
    const { productId, issueId } = selectedProductInfo;

    const payload: PersonalSavingApiType["signUpSection"] = {
      emailAddress,
      phoneNumber,
      password,
      productReference: productId,
      productIssueId: issueId,
      domain: "INDIVIDUAL",
      ...(isFeatureActive(FeatureFlag.GTM) && {
        utm: {
          source: getCookie("utm_source"),
          medium: getCookie("utm_medium"),
          content: getCookie("utm_content"),
        },
      }),
    };

    if (environment.googleReCAPTCHA.enabled && typeof grecaptcha !== "undefined") {
      const siteKey = environment.googleReCAPTCHA.config.siteKey;
      grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise.execute(siteKey, { action: "submit" }).then((token: string) => {
          saveData({
            method: "POST",
            body: JSON.stringify({ ...payload, captchaToken: token }),
          });
        });
      });
    } else {
      saveData({
        method: "POST",
        body: JSON.stringify({ ...payload, captchaToken: "" }),
      });
      appInsights.trackEvent({ name: `error-on-re-captcha-token-generate` });
    }
  };

  useEffect(() => {
    if (saveReqStatus.success) {
      const { productId, issueId } = selectedProductInfo;

      const newData = { ...personalSavingData };
      const [emailAddress, phoneNumber, password] = getValues([
        "emailAddress",
        "phoneNumber",
        "password",
      ]);

      const values: PersonalSavingApiType["signUpSection"] = {
        emailAddress,
        phoneNumber,
        password,
        productReference: productId,
        productIssueId: issueId,
        domain: "INDIVIDUAL",
      };

      const noOfSecurityCodeSent =
        (newData?.individualApplicationSections?.verifySection?.noOfSecurityCodeSent || 0) + 1;
      newData.individualApplicationSections.verifySection = { noOfSecurityCodeSent };
      newData.individualApplicationSections.signUpSection = values;
      newData.applicationID = saveResponse.applicationRef;
      setPersonalSavingData(newData);

      const newState = { ...stepperConfig };
      newState[Stage.SIGN_UP].status = StepStatus.COMPLETE;
      newState[Stage.VERIFY].status = StepStatus.INCOMPLETE;
      setStepperConfig(newState);

      setCurrentStage(Stage.VERIFY);
    }
  }, [saveReqStatus.success]);

  useEffect(() => {
    const { emailAddress, password, phoneNumber } =
      personalSavingData?.individualApplicationSections?.signUpSection;

    reset({
      reTypeEmail: emailAddress,
      emailAddress,
      password,
      reTypePassword: password,
      phoneNumber,
    });
  }, [personalSavingData?.individualApplicationSections?.signUpSection]);

  useEffect(() => {
    if (saveReqStatus.error) {
      handleSignUpError(saveError, setError, openModal, setShowGenericError);
    }
  }, [saveReqStatus.error]);

  return (
    <>
      {!storeStatus?.success && <Overlay spinner />}
      <Box mt="6.4rem">
        <Heading color="$text-01" size="h1" as="h1" mb="1.6rem">
          Sign up details
        </Heading>
        <Text color="$text-03" textStyle="body-03">
          Are you already a customer with Allica?{" "}
          <Link
            textDecoration="underline"
            href={environment.ui.onlineBanking}
            color="$interactive-01"
          >
            Click here
          </Link>
        </Text>

        <FormProvider {...formMethods}>
          <SignUpForm isSubmitting={saveReqStatus.loading} onSubmit={onSubmit} />
        </FormProvider>

        <Modal
          headerTitle="Email already in use"
          primaryButtonText="Login"
          primaryAction={navigateToOB}
          secondaryButtonText="Cancel"
          isOpen={showModal}
          onClose={closeModal}
          variant="error"
        >
          <Text as="p" textStyle="body-03" color="$text-02">
            Login to your account to complete the application.
          </Text>
        </Modal>
      </Box>
    </>
  );
};
