import { Steps, StepStatus } from "@allica/ui-react";
import { Stage, StageMVP } from "../BusinessRewards.types";

export const businessRewardsInitialStepperConfigMVP: Steps<StageMVP> = {
  [StageMVP.SIGN_UP]: {
    label: "Sign Up",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [StageMVP.VERIFY]: {
    label: "Verify",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [StageMVP.BUSINESS]: {
    label: "Business",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [StageMVP.APPLICANT]: {
    label: "Applicant",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [StageMVP.SUMMARY]: {
    label: "Summary",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
};

export const businessRewardsInitialStepperConfig: Steps<Stage> = {
  [Stage.SIGN_UP]: {
    label: "Sign Up",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.VERIFY]: {
    label: "Verify",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.BUSINESS]: {
    label: "Business",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.APPLICANT]: {
    label: "Applicant",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.DEPOSIT]: {
    label: "Deposit",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.ACCOUNT]: {
    label: "Account",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.DOCUMENT]: {
    label: "Document",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.SUMMARY]: {
    label: "Summary",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
};
