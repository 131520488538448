import { Steps, StepStatus } from "@allica/ui-react";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { AlertStage, Stage } from "../BusinessRewards.types";
import {
  businessRewardsInitialStepperConfig,
  businessRewardsInitialStepperConfigMVP,
} from "./businessRewardsInitialStepperConfig";
import { businessRewardsStepOrder, businessRewardsStepOrderMVP } from "./businessRewardsStepOrder";

const isPhaseTwo = isFeatureActive(FeatureFlag.BRA_PHASE_TWO);
const stepOrder = (isPhaseTwo ? businessRewardsStepOrder : businessRewardsStepOrderMVP) as Stage[];

export const mapBusinessRewardsStepper = (stageFromApi: Stage, alertStageList: Stage[]) => {
  const initialStepperConfig = isPhaseTwo
    ? businessRewardsInitialStepperConfig
    : businessRewardsInitialStepperConfigMVP;

  const stepperConfig = { ...initialStepperConfig } as Steps<Stage>;
  const alertStages: AlertStage[] = [];

  for (let index = 0; index < stepOrder.length; index++) {
    if (stepOrder[index] === stageFromApi) {
      stepperConfig[stepOrder[index]].status = StepStatus.INCOMPLETE;
      break;
    } else if (alertStageList.includes(stepOrder[index])) {
      stepperConfig[stepOrder[index]].status = StepStatus.ALERT;
      alertStages.push(stepOrder[index] as AlertStage);
    } else stepperConfig[stepOrder[index]].status = StepStatus.COMPLETE;
  }

  stepperConfig[Stage.SIGN_UP].disabled = true;
  stepperConfig[Stage.VERIFY].disabled = true;
  return { stepperConfig, currentStep: stageFromApi, alertStages };
};
