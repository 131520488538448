import { useEffect } from "react";
import { appInsights } from "src/core/app/ApplicationInsights";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  getTextStyle,
  Text,
  WarningIcon,
} from "@allica/ui-react";
import { Box, Flex } from "@chakra-ui/react";

import { ErrorBannerProps } from "./ErrorBanner.types";
import { isFeatureActive } from "../feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";

const ErrorBanner = ({
  title,
  description,
  trackException,
  descriptionCallbacks,
  titleCallback,
  trackEvent,
  ...styleProps
}: ErrorBannerProps) => {
  useEffect(() => {
    if (isFeatureActive(FeatureFlag.EVENT_INSIGHTS)) {
      trackException && appInsights.trackException(trackException);
      trackEvent && appInsights.trackEvent(trackEvent);
    }
  }, []);

  return (
    <Alert status="error" mt={styleProps?.mt || "6.4rem"} {...styleProps}>
      <WarningIcon color="$danger-01" mr="1.6rem" />
      <Box>
        <AlertTitle>
          {title}
          {titleCallback && (
            <>
              <br />
              <Text
                mt="0.8rem"
                as="a"
                cursor="pointer"
                onClick={titleCallback.method}
                textDecoration="underline"
              >
                {titleCallback.label}
              </Text>
            </>
          )}
        </AlertTitle>
        {description && <AlertDescription>{description}</AlertDescription>}
        <Flex direction="column">
          {descriptionCallbacks &&
            descriptionCallbacks.map((descriptionCallback) => (
              <Text
                key={descriptionCallback.label}
                as="a"
                mt="0.4rem"
                lineHeight="2.4rem"
                color="$link-01"
                sx={getTextStyle("body-03")}
                cursor="pointer"
                onClick={descriptionCallback.method}
                _hover={{ textDecoration: "underline" }}
              >
                {descriptionCallback.label}
              </Text>
            ))}
        </Flex>
      </Box>
    </Alert>
  );
};

export default ErrorBanner;
