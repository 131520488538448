import { useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { FormPageLayout, MobileStepper, Stepper, StepStatus } from "@allica/ui-react";
import { Box, GridItem } from "@chakra-ui/react";

import { usePersonalContext } from "../context/PersonalContext";
import { Stage } from "../PersonalSavings.types";
import { Outcome, Privacy } from "../stages";
import ErrorBanner from "src/components/connection-error/ErrorBanner";
import { Overlay } from "src/components/overlay/Overlay";
import { ExitApplicationModal } from "../../../shared/layout/ExitApplicationModal";
import environment from "src/environments/environment";
import { stepOrder } from "../context/stepOrder";
import { personalSavingsUrl } from "../PersonalSavings.utils";
import Header from "src/components/header/Header";

const PersonalSavingsLayout = () => {
  const {
    isPersonalSavingDataLoading,
    currentStage,
    setCurrentStage,
    stepperConfig,
    showGenericError,
    setShowGenericError,
    selectedProductInfo,
    showNominatedAccountIncomplete,
    setShowNominatedAccountIncomplete,
  } = usePersonalContext();

  const [showExitApplicationModal, setShowExitApplicationModal] = useState<boolean>(false);
  const [exitByStepper, setExitByStepper] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const params = useParams();
  const eyebrowText = "Application for:";
  const formTitle = selectedProductInfo?.description ?? "";

  const alertUser = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  }, []);

  const removeBeforeUnloadShowModal = () => {
    window.removeEventListener("beforeunload", alertUser);
    setExitByStepper(true);
    setShowExitApplicationModal(true);
  };

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView();
      window.scrollTo(0, 0);
    }
  }, [currentStage]);

  useEffect(() => {
    if (scrollRef && scrollRef.current && showGenericError) {
      scrollRef.current.scrollIntoView();
      window.scrollTo(0, 0);
    }
  }, [showGenericError]);

  useEffect(() => {
    setShowGenericError(false);
    if (currentStage !== Stage.SUMMARY) setShowNominatedAccountIncomplete(false);
  }, [currentStage]);

  return (
    <>
      <Header
        setShowExitApplicationModal={setShowExitApplicationModal}
        setExitByStepper={setExitByStepper}
        applicationComplete={stepperConfig[Stage.SUMMARY].status === StepStatus.COMPLETE}
        applicationStarted={stepperConfig[Stage.SIGN_UP].status === StepStatus.COMPLETE}
      />
      <Box as="main" mt="7.2rem" h={{ base: "calc(100vh - 180px)", lg: "calc(100vh - 7.2rem)" }}>
        {isPersonalSavingDataLoading ? (
          <Overlay spinner />
        ) : stepperConfig[Stage.SIGN_UP].status === StepStatus.INACTIVE ? (
          <Privacy />
        ) : stepperConfig[Stage.SUMMARY].status === StepStatus.COMPLETE ? (
          <Outcome />
        ) : (
          currentStage && (
            <>
              <MobileStepper
                stepOrder={stepOrder}
                eyebrowText={eyebrowText}
                formTitle={formTitle}
                currentStep={currentStage}
                setStep={setCurrentStage}
                steps={stepperConfig}
                previousStepDisabledCallback={removeBeforeUnloadShowModal}
                noPreviousStepCallback={() => navigate(personalSavingsUrl(params.product))}
                showBelow="lg"
                notificationAriaLabel="A previously completed step requires attention"
              />
              <FormPageLayout
                sidebarComponent={
                  <Stepper
                    stepOrder={stepOrder}
                    eyebrowText={eyebrowText}
                    formTitle={formTitle}
                    currentStep={currentStage}
                    setStep={setCurrentStage}
                    steps={stepperConfig}
                    previousStepDisabledCallback={removeBeforeUnloadShowModal}
                    backgroundColor="#ffffff"
                    noPreviousStepCallback={() => navigate(personalSavingsUrl(params.product))}
                  />
                }
              >
                <GridItem ref={scrollRef} colSpan={{ base: 4, sm: 8 }} as="section">
                  {showGenericError && (
                    <ErrorBanner
                      title="Something went wrong"
                      description="Please retry saving your changes by clicking the Save and continue button below."
                    />
                  )}
                  {showNominatedAccountIncomplete && !showGenericError && (
                    <ErrorBanner
                      title="Incomplete application"
                      description="The change you made to your name requires you to update the Account page."
                      trackEvent={{
                        name: "nominated-account-incomplete-at-summary",
                      }}
                      descriptionCallbacks={[
                        {
                          label: "Go to Account page",
                          method: () => setCurrentStage(Stage.ACCOUNT),
                        },
                      ]}
                    />
                  )}
                </GridItem>
                <GridItem colStart={{ base: 1, sm: 2 }} colSpan={{ base: 4, sm: 6 }} as="section">
                  <Outlet />
                </GridItem>
              </FormPageLayout>
            </>
          )
        )}
        <ExitApplicationModal
          isOpen={showExitApplicationModal}
          onClose={() => setShowExitApplicationModal(false)}
          exitByStepper={exitByStepper}
          exitAction={() => {
            window.location.href = environment.ui.allicaPortal;
          }}
        />
      </Box>
    </>
  );
};

export default PersonalSavingsLayout;
