import { useState, createContext, useContext, useEffect } from "react";
import { Steps, StepStatus } from "@allica/ui-react";

import { initialStepperConfig } from "./initialStepperConfig";
import { initialPersonalSavingData } from "./initialPersonalSavingData";
import {
  PersonalContextProviderType,
  PersonalSavingDataTypes,
  PersonalSavingApiType,
  PersonalContextType,
  Stage,
  CurrentApplicationStage,
} from "../PersonalSavings.types";
import { DepositsAPI } from "src/core/service";
import { mapStepper } from "./mapStepper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { completedStepperConfig } from "./completedStepperConfig";
import { compareIndividualsNames, getChosenProductInfo } from "src/core/utils";
import { useStore } from "src/core/store/StoreContext";
import { appInsights } from "src/core/app/ApplicationInsights";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { ConfirmationOfPayeeResponse } from "src/core/service/confirmation-of-payee-api/ConfirmationOfPayeeApi.types";
import { ProductCatalogItem } from "src/core/service/products-api/ProductsApi.types";

const RISK_CHECK_STAGES: CurrentApplicationStage[] = [
  "COMPLETED",
  "SPLITTING",
  "RISK_CHECK_IN_PROGRESS",
  "PROCESSING_ERROR",
  "IN_REVIEW",
];

const PersonalContext = createContext<PersonalContextType>({
  currentStage: null,
  stepperConfig: initialStepperConfig,
  personalSavingData: initialPersonalSavingData,
  setCurrentStage: () => null,
  setPersonalSavingData: () => null,
  setStepperConfig: () => null,
  showGenericError: false,
  setShowGenericError: () => null,
  isPersonalSavingDataLoading: false,
  selectedProductInfo: {} as ProductCatalogItem,
  showNominatedAccountIncomplete: false,
  setShowNominatedAccountIncomplete: () => null,
});

const PersonalContextProvider = ({
  applicationID,
  applicationIDStatus,
  children,
}: PersonalContextProviderType) => {
  const [currentStage, setCurrentStage] = useState<Stage | null>(null);
  const [stepperConfig, setStepperConfig] = useState<Steps<Stage>>(initialStepperConfig);
  const [personalSavingData, setPersonalSavingData] =
    useState<PersonalSavingDataTypes>(initialPersonalSavingData);
  const [showGenericError, setShowGenericError] = useState<boolean>(false);
  const [showNominatedAccountIncomplete, setShowNominatedAccountIncomplete] =
    useState<boolean>(false);
  const [selectedProductInfo, setSelectedProductInfo] = useState<ProductCatalogItem>(
    {} as ProductCatalogItem
  );

  const { products, storeStatus } = useStore();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const locationStage = location.pathname.split("/").reverse().filter(Boolean)[0] as Stage;

  const {
    response: personalSavingSavedData,
    status: getReqStatus,
    request: getPersonalSavingData,
  } = DepositsAPI<PersonalSavingApiType>(`applications/individuals/${applicationID}/ALL`);

  useEffect(() => {
    if (applicationID) getPersonalSavingData();
  }, [applicationID]);

  useEffect(() => {
    if (storeStatus?.success) {
      const productInfo = getChosenProductInfo(products);
      setSelectedProductInfo(productInfo);
    }
  }, [storeStatus?.success]);

  useEffect(() => {
    if (getReqStatus.error) {
      if (isFeatureActive(FeatureFlag.EVENT_INSIGHTS)) {
        appInsights.trackEvent({ name: `error-on-save-and-continue` });
      }
      throw new Error("Unable to get the current personal application stage");
    }

    if (getReqStatus.success) {
      const { currentApplicationStage, cobApplicationId, ...applicationSections } =
        personalSavingSavedData;

      let nameMatchesAccount = true;

      if (personalSavingSavedData.accountSection) {
        const { firstName, middleName, lastName } = personalSavingSavedData.aboutYouSection;

        nameMatchesAccount = compareIndividualsNames({
          firstName,
          middleName,
          lastName,
          accountName: personalSavingSavedData.accountSection.name,
        });
      }

      if (isFeatureActive(FeatureFlag.EVENT_INSIGHTS)) {
        appInsights.trackEvent({ name: `personal-deposits-${params.product}-started` });
        appInsights.trackEvent({ name: `save-and-continue` });
      }

      const newPersonalSavingData = {
        cobApplicationId,
        applicationID: applicationID ?? "",
        currentApplicationStage,
        individualApplicationSections: {
          ...personalSavingData.individualApplicationSections,
          ...applicationSections,
          outcome: { success: currentApplicationStage === "COMPLETED" },
        },
      };

      if (!nameMatchesAccount) {
        newPersonalSavingData.individualApplicationSections.accountSection = {
          name: "",
          sortCode: "",
          accountNumber: "",
          copResponse: {} as ConfirmationOfPayeeResponse,
        };
      }

      setPersonalSavingData(newPersonalSavingData);

      if (currentApplicationStage) {
        if (Object.keys(Stage).includes(currentApplicationStage)) {
          const { stepperConfig, currentStep } = mapStepper(
            Stage[currentApplicationStage as keyof typeof Stage]
          );
          if (!nameMatchesAccount) {
            stepperConfig[Stage.ACCOUNT].status = StepStatus.ALERT;
            setShowNominatedAccountIncomplete(true);
          }
          setStepperConfig(stepperConfig);
          setCurrentStage(currentStep);
        } else if (RISK_CHECK_STAGES.includes(currentApplicationStage)) {
          setStepperConfig(completedStepperConfig);
        }
      }
    }
  }, [getReqStatus]);

  useEffect(() => {
    if (locationStage === params.product) {
      setCurrentStage(null);
      const newState = { ...initialStepperConfig };
      newState[Stage.SIGN_UP].status = StepStatus.INACTIVE;
      setStepperConfig(newState);
    }
    if (Object.values(Stage).includes(locationStage) && locationStage !== currentStage) {
      setCurrentStage(locationStage as Stage);
    }
  }, [location]);

  useEffect(() => {
    if (currentStage && locationStage !== currentStage) {
      appInsights.trackPageView({ name: currentStage ? currentStage : "privacy-begin" });
      navigate(currentStage);
    }
  }, [currentStage]);

  const retrievingPersonalSavingData =
    applicationIDStatus.loading ||
    ((applicationIDStatus.success || !!applicationID) &&
      !personalSavingData.currentApplicationStage) ||
    getReqStatus.loading;

  return (
    <PersonalContext.Provider
      value={{
        currentStage,
        stepperConfig,
        personalSavingData,
        setCurrentStage,
        setStepperConfig,
        setPersonalSavingData,
        showGenericError,
        setShowGenericError,
        isPersonalSavingDataLoading: retrievingPersonalSavingData,
        selectedProductInfo,
        setShowNominatedAccountIncomplete,
        showNominatedAccountIncomplete,
      }}
    >
      {children}
    </PersonalContext.Provider>
  );
};

const usePersonalContext = () => useContext(PersonalContext);

export { usePersonalContext, PersonalContextProvider };
