import { snakeToCamelCase } from "src/core/utils";
import {
  FormValues,
  ParsedBCAConfig,
  Question,
  QuestionName,
} from "./BusinessRewardsAccountActivity.types";

export const parseBcaConfiguration = () => {
  const filteredQuestions = mockBcaConfiguration.questions.filter(
    (question) => question.id !== "ANNUAL_BUSINESS_TURNOVER",
  );

  return filteredQuestions.reduce<ParsedBCAConfig>(
    (prev, curr) => ({
      ...prev,
      [curr.id]: { options: curr.options, id: curr.id, description: curr.description },
    }),
    {} as ParsedBCAConfig,
  );
};

export const getOptionDescriptionById = (question: Question, optionId: number) => {
  const option = question.options.find((option) => option.id === Number(optionId));
  if (!option) return "";

  return option.name;
};

export const getOptionDescriptionsByIds = (questionId: QuestionName, optionIds: string[]) => {
  const question = mockBcaConfiguration.questions.find((question) => question.id === questionId);
  if (!question) return "";

  const options: string[] = [];

  optionIds.forEach((id) => {
    options.push(getOptionDescriptionById(question, Number(id)));
  });

  return options.filter(Boolean).join(", ");
};

export const parseAccountActivityAnswers = (
  answers: { questionId: string; selectedOptions: number[] }[],
) => {
  return answers.reduce<FormValues>((prev, curr) => {
    let answers: string[] | string = curr.selectedOptions.map((number) => String(number));
    if (curr.questionId === QuestionName.MONTHLY_PAYMENTS_IN) {
      answers = answers.shift()!;
    }
    return {
      ...prev,
      [snakeToCamelCase(curr.questionId)]: answers,
    };
  }, {} as FormValues);
};

export const mockBcaConfiguration = {
  questions: [
    {
      id: "ANNUAL_BUSINESS_TURNOVER",
      type: "SINGLE_SELECT",
      description:
        "What is the expected annual turnover of your business this financial year? If you aren’t sure, use last year’s actual figures.",
      name: "Annual business turnover",
      stepperName: "Business turnover",
      options: [
        {
          id: 1,
          name: "Less than £100k",
          value: "100000",
        },
        {
          id: 2,
          name: "£100k-£250k",
          value: "250000",
        },
        {
          id: 3,
          name: "£250k - £500k",
          value: "500000",
        },
        {
          id: 4,
          name: "£500k - £1m",
          value: "1000000",
        },
        {
          id: 5,
          name: "£1m - £2m",
          value: "2000000",
        },
        {
          id: 6,
          name: "£2m - £5m",
          value: "5000000",
        },
        {
          id: 7,
          name: "More than £5m",
          value: "10000000",
        },
      ],
    },
    {
      id: "MONTHLY_PAYMENTS_IN",
      type: "SINGLE_SELECT",
      description: "How much do you expect to pay into this account each month?",
      name: "Monthly Payments In",
      stepperName: "Monthly payments",
      options: [
        {
          id: 1,
          name: "Less than £1k",
          value: "LESS_THAN_1K",
        },
        {
          id: 2,
          name: "£1k to £5k",
          value: "BETWEEN_1K_AND_5K",
        },
        {
          id: 3,
          name: "£5k to £10k",
          value: "BETWEEN_5K_AND_10K",
        },
        {
          id: 4,
          name: "£10k to £25k",
          value: "BETWEEN_10K_AND_25K",
        },
        {
          id: 5,
          name: "£25k to £50k",
          value: "BETWEEN_25K_AND_50K",
        },
        {
          id: 6,
          name: "£50k to £100k",
          value: "BETWEEN_50K_AND_100K",
        },
        {
          id: 7,
          name: "More than £100k",
          value: "MORE_THAN_100K",
        },
      ],
    },
    {
      id: "SOURCE_OF_FUNDS",
      type: "MULTIPLE_SELECT",
      description: "Where will the money to fund this account come from? Select all that apply",
      name: "Source of Funds",
      stepperName: "Source of funds",
      options: [
        {
          id: 1,
          name: "Another business bank account",
          value: "ANOTHER_BUSINESS_BANK_ACCOUNT",
        },
        {
          id: 2,
          name: "Payments from customers",
          value: "PAYMENTS_FROM_CUSTOMERS",
        },
        {
          id: 3,
          name: "Investor funding",
          value: "INVESTOR_FUNDING",
        },
        {
          id: 4,
          name: "Borrowing, loans or credit",
          value: "BORROWING_LOANS_CREDIT",
        },
      ],
    },
    {
      id: "PURPOSE_OF_ACCOUNT",
      type: "MULTIPLE_SELECT",
      description: "What do you plan to use this account for?",
      name: "Purpose of Account",
      stepperName: "Account purpose",
      options: [
        {
          id: 1,
          name: "As a primary business account",
          value: "PRIMARY_BUSINESS_ACCOUNT",
        },
        {
          id: 2,
          name: "As a secondary business account",
          value: "SECONDARY_BUSINESS_ACCOUNT",
        },
        {
          id: 3,
          name: "Bank payments",
          value: "BANK_PAYMENTS",
        },
        {
          id: 4,
          name: "Card Payments",
          value: "CARD_PAYMENTS",
        },
        {
          id: 5,
          name: "Online purchases & e-commerce",
          value: "ONLINE_PURCHASE_AND_ECOMMERCE",
        },
        {
          id: 6,
          name: "To pay suppliers",
          value: "SUPPLIER_PAYMENTS",
        },
        {
          id: 7,
          name: "Other business expenses",
          value: "OTHER_BUSINESS_EXPENSES",
        },
      ],
    },
  ],
};
