import { ChangeEvent, useEffect, useState } from "react";
import { Button, ErrorIcon, SearchIcon } from "@allica/ui-react";
import { Box, Spinner } from "@chakra-ui/react";
import { FormGroup } from "src/components/input-set/FormGroup";
import { AutoCompleteSearch } from "src/components/autocomplete";
import { CompanySearchProps } from "./CompanySearch.types";
import { useStore } from "src/core/store/StoreContext";
import { useFormContext } from "react-hook-form";
import { BusinessProfileTypes, CompanyFormValues } from "../Company.types";
import CompanyHouseApi from "src/core/service/company-house-api/CompanyHouseApi";
import { getUpdatedFrontendFields } from "../Company.utils";

const getModifiedResponse = (arr: { companyName: string; companyRegistrationNumber: string }[]) => {
  return arr?.map((item) => ({
    ...item,
    label: `${item?.companyName}, ${item?.companyRegistrationNumber}`,
  }));
};

const eligibleCompanyTypes = [
  "PUBLIC_LIMITED_COMPANY",
  "LIMITED_COMPANY",
  "LTD_LIABILITY_PARTNERSHIP",
];

const CompanySearch = ({ setSearchCompanyPage }: CompanySearchProps) => {
  const referenceData = useStore();
  const {
    register,
    setValue,
    setError,
    reset,
    clearErrors,
    formState: { errors },
  } = useFormContext<CompanyFormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [companySearchURL, setCompanySearchURL] = useState<string>("");
  const [companyID, setCompanyID] = useState("");
  const [companySuggestions, setCompanySuggestions] = useState<any[]>([]);

  const [isFieldBlurred, setIsFieldBlurred] = useState(false);

  const companySearchValidate = (value: string) => {
    return isFieldBlurred && value
      ? "Enter your company name or Companies House number" //TODO: Confirm error msg with PO
      : true;
  };

  const fieldOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFieldBlurred(true);
    onBlur(e);
  };
  const fieldOnFocus = () => setIsFieldBlurred(false);

  const { onBlur, ...restReg } = register("businessSearchName", {
    required: "Enter your company name or Companies House number",
    validate: companySearchValidate,
  });

  const {
    response: companiesResponse,
    status: companySearchStatus,
    request: getCompanies,
  } = CompanyHouseApi<any[]>(companySearchURL);

  const {
    response: companyIDResponse,
    status: companyIDStatus,
    request: getCompanyDetails,
  } = CompanyHouseApi<any>(`companies/${companyID}?source=DEPOSIT`);

  useEffect(() => {
    if (companySearchURL) {
      const timer = setTimeout(async () => {
        getCompanies();
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [companySearchURL]);

  useEffect(() => {
    if (companySearchStatus.success) {
      if (!!companiesResponse) {
        const newCompanyResponse = getModifiedResponse(companiesResponse);
        setCompanySuggestions(newCompanyResponse);
        setIsLoading(false);
      }
    }
  }, [companySearchStatus.success]);

  useEffect(() => {
    if (companySearchStatus.error) {
      setCompanySuggestions([]);
      setIsLoading(false);
      setError?.("businessSearchName", {
        message: "Our company lookup isn't currently working.",
      });
    }
  }, [companySearchStatus.error]);

  useEffect(() => {
    if (companyIDStatus.success) {
      const companiesHouseResponse = companyIDResponse;
      const businessDataResponse = companiesHouseResponse?.businessProfile as BusinessProfileTypes;

      if (
        !!businessDataResponse &&
        businessDataResponse?.companyStatus?.toLowerCase() === "active" &&
        eligibleCompanyTypes?.includes(businessDataResponse?.businessEntityType)
      ) {
        const { connectedIndividuals, connectedBusinesses, ...businessDetails } =
          businessDataResponse;

        const data = {
          companiesHouseResponse: companiesHouseResponse,
          ...businessDetails,
        } as CompanyFormValues;

        const {
          businessStartDate,
          sisCodeString,
          newTaxIdentifications,
          registeredAddress,
          countries,
        } = getUpdatedFrontendFields(data, referenceData);

        reset({
          companiesHouseResponse: companiesHouseResponse,
          ...businessDetails,
          businessStartDate: businessStartDate,
          businessSicCodes: sisCodeString,
          taxResidencies: newTaxIdentifications?.length > 1 ? newTaxIdentifications : [],
          registeredAddress: registeredAddress,
          countries: countries,
          confirmation: false,
        });
        setIsLoading(false);
        setSearchCompanyPage(false);
      } else if (businessDataResponse?.businessEntityType === "OVERSEAS_COMPANY") {
        setError?.("businessSearchName", {
          message:
            "The company you have selected is showing as not being registered in the UK at Companies House.",
        });
        setIsLoading(false);
        setCompanyID("");
      } else if (!eligibleCompanyTypes?.includes(businessDataResponse?.businessEntityType)) {
        setError?.("businessSearchName", {
          message:
            "The company you have selected is showing as having the status of a company type which Allica Bank do not support at this time.",
        });
      } else if (businessDataResponse?.companyStatus !== "active") {
        setError?.("businessSearchName", {
          message:
            "The company you have selected is showing a status that is not ‘active’ at Companies House.",
        });
        setIsLoading(false);
        setCompanyID("");
      }
    }
  }, [companyIDStatus.success]);

  useEffect(() => {
    setIsLoading(false);
    if (companyIDStatus.error) {
      setError?.("businessSearchName", {
        message: "Our company lookup isn't currently working.",
      });
      setCompanyID("");
    }
  }, [companyIDStatus.error]);

  const queryChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e?.target;
    clearErrors?.("businessSearchName");
    if (value.trim()) {
      setCompanySearchURL(`companysearch?companyName=${value}`);
    } else {
      setCompanySearchURL("");
      setCompanySuggestions([]);
    }
  };

  const onClickSuggestion = async (selectedObj: any) => {
    setValue("businessSearchName", selectedObj?.label);
    setIsLoading(true);
    const { companyRegistrationNumber } = selectedObj;
    if (companyRegistrationNumber) {
      setCompanyID(companyRegistrationNumber);
    }
  };

  useEffect(() => {
    if (companyID) {
      getCompanyDetails();
    }
  }, [companyID]);

  return (
    <>
      <FormGroup
        label={"Search company name or company no."}
        isRequired
        mb={"4rem"}
        error={errors.businessSearchName?.message}
      >
        <AutoCompleteSearch
          suggestions={companySuggestions}
          queryChangeHandler={queryChangeHandler}
          onClickSuggestion={onClickSuggestion}
          isError={!!errors?.businessSearchName}
          onBlur={fieldOnBlur}
          onFocus={fieldOnFocus}
          rightIcon={
            !!errors.businessSearchName ? (
              <ErrorIcon color="$danger-01" />
            ) : isLoading ? (
              <Spinner />
            ) : (
              <SearchIcon boxSize="2.4rem" />
            )
          }
          {...restReg}
        />
      </FormGroup>
      <Box mb={"8rem"}>
        <Button
          onClick={() =>
            setError("businessSearchName", {
              message:
                "Your company must be registered at Companies House to be eligible for this account",
            })
          }
          variant="text"
        >
          Can’t find your company?
        </Button>
      </Box>
    </>
  );
};

export default CompanySearch;
