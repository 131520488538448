type Option = { id: number; name: string; value: string };
export type Question = { id: string; options: Option[]; description: string };

export enum QuestionName {
  PURPOSE_OF_ACCOUNT = "PURPOSE_OF_ACCOUNT",
  MONTHLY_PAYMENTS_IN = "MONTHLY_PAYMENTS_IN",
  SOURCE_OF_FUNDS = "SOURCE_OF_FUNDS",
}

export type ParsedBCAConfig = {
  [key in QuestionName]: Question;
};

export interface FormValues {
  purposeOfAccount: string[];
  monthlyPaymentsIn: string;
  sourceOfFunds: string[];
}
