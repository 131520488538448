import { FieldValues } from "react-hook-form";
import { Location } from "react-router-dom";
import { scvRefDefault } from "src/core/service/deposits-api/DepositsApi.types";
import { AlertStage as BusinessRewardsAlertStage } from "src/pages/business-rewards/BusinessRewards.types";
import { AlertStage as BusinessAlertStage } from "src/pages/business-savings/BusinessSavings.types";
import { ProductCatalogItem } from "../service/products-api/ProductsApi.types";

export const regExDictionary = {
  NON_DIGIT_CHARACTERS: /\D/g,
  CAPTURE_TWO_DIGITS_WITH_LOOK_AHEAD: /(\d{2})(?=\d)/g,
};

export const concatenateName = ({
  title,
  firstName,
  middleName,
  lastName,
}: {
  title?: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
}) => {
  const nameArray = [title, firstName, middleName, lastName];
  return nameArray.filter(Boolean).join(" ");
};

export const concatenateAddress = ({
  addressLine1,
  addressLine2,
  addressLine3,
  city,
  county,
  postCode,
}: {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  county: string;
  postCode: string;
}): string => {
  const addressLinesArray = [addressLine1, addressLine2, addressLine3, city, county, postCode];
  return addressLinesArray.filter(Boolean).join(", ");
};

export const formatCurrency = (value: string): string => {
  const numberValue = Number(value);

  if (!isNaN(numberValue)) {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(Number(value));
  }

  return "";
};

export const getDescription = (name: string, values: scvRefDefault[]) => {
  return values.find((value) => value.name === name)?.description || name;
};

export const getAndJoinDescriptions = (names: string[], values: scvRefDefault[]) => {
  const descriptionArray = names.map((name) => getDescription(name, values));
  return descriptionArray.join(", ");
};

export const dateFormatReverse = (date: string) => {
  return date?.split("-")?.reverse()?.join("/") ?? "";
};

export const getChosenProductInfo = (productList: ProductCatalogItem[]): ProductCatalogItem => {
  const filteredProduct = productList.filter((product) =>
    window.location.pathname.includes(product.pageUrl),
  );

  if (!filteredProduct.length) {
    throw new Error(
      "Unable to find the chosen product information, you may have provided an invalid product URL",
    );
  }
  return filteredProduct.reduce((productA, productB) =>
    productA.issueNumber > productB.issueNumber ? productA : productB,
  );
};

export const getSentenceCase = (value?: string): string => {
  if (!value) return "";
  const lowerCaseValue = value.toLowerCase().replace(/-|_/g, " ");
  return lowerCaseValue.charAt(0).toUpperCase() + lowerCaseValue.slice(1);
};

export const snakeToCamelCase = (str: string) =>
  str.toLowerCase().replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());

export const compareIndividualsNames = ({
  firstName,
  middleName,
  lastName,
  accountName,
}: {
  firstName: string;
  middleName: string | null;
  lastName: string;
  accountName: string;
}): boolean => {
  const fullName = concatenateName({ firstName, middleName, lastName });
  return fullName === accountName;
};

export const dateValidate = (value: string) => {
  const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  const now = new Date();
  let msg: string | boolean = true;
  const [day, month, year] = value.split("/");
  if (+day && +month && +year && dateFormat.test(value)) {
    const birth = new Date(+year, +month - 1, +day);
    const diff = new Date(now.valueOf() - birth.valueOf());
    const age = Math.abs(diff.getFullYear() - 1970);
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (+year % 400 === 0 || (+year % 100 !== 0 && +year % 4 === 0)) {
      monthLength[1] = 29;
    }
    if (
      +day < 1 ||
      +day > monthLength[+month - 1] ||
      +month > 12 ||
      age > 150 ||
      +year < 100 ||
      birth > now
    ) {
      msg = "Please enter a valid date of birth";
    } else if (age < 18) {
      msg = "You must be over the age of 18 to apply for an account";
    }
  } else {
    msg = "Please enter a valid date of birth";
  }
  return msg;
};

export const getProperty = (obj: FieldValues, path?: string) => {
  return path
    ? path.split(".").reduce((prev, curr) => {
        return prev ? prev[curr] : null;
      }, obj)
    : obj;
};

export const getDefaultPath = (location: Location): string => {
  const locationArray = location.pathname.split("/").map((i) => i.toLowerCase());
  let path = "personal/savings/24-month-fixed-term-deposit";

  if (locationArray.includes("current-account")) {
    path = "business/current-account";
  } else if (locationArray.includes("business")) {
    path = "business/savings/24-month-fixed-term-deposit";
  }

  return path;
};

export const formatSortCode = (sortCode: string): string => {
  let formattedValue = sortCode.replace(regExDictionary.NON_DIGIT_CHARACTERS, "");
  formattedValue = formattedValue.slice(0, 6);
  formattedValue = formattedValue.replace(
    regExDictionary.CAPTURE_TWO_DIGITS_WITH_LOOK_AHEAD,
    "$1-",
  );

  return formattedValue;
};

export const joinAlertStages = (
  incompleteStages: BusinessAlertStage[] | BusinessRewardsAlertStage[],
): string => {
  const length = incompleteStages.length;
  const pageText = length > 1 ? "pages" : "page";

  const parsedStages = incompleteStages.map((stage, index) => {
    const description = getSentenceCase(stage);
    if (length === 1) return description;
    if (index === length - 1) return `and ${description}`;
    if (index !== length - 2) return `${description},`;
    return description;
  });
  return `${parsedStages.join(" ")} ${pageText}`;
};

export const getFilteredOptions = (
  currentCode: string,
  usedOptions: string[],
  lists: scvRefDefault[]
) => {
  const addExistingCode = usedOptions?.filter((codes: string) => codes !== currentCode);
  const filteredLists = lists.filter((item) => !addExistingCode?.includes(item?.name));
  return filteredLists;
};

export const moveItemToFirstPosition = (name: string, array: scvRefDefault[]) => {
  const item = array.find((item) => item.name === name);
  const newArray = array.filter((item) => item.name !== name);
  if (item) newArray.unshift(item);

  return newArray;
};

export const moveUKToFirstPosition = (options: scvRefDefault[]) =>
  moveItemToFirstPosition("GB", options);
export const moveBritishToFirstPosition = (options: scvRefDefault[]) =>
  moveItemToFirstPosition("BRITISH", options);
