import {
  Button,
  CheckboxGroup,
  ChevronRightIcon,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  SimpleSelect,
  StepStatus,
  Text,
} from "@allica/ui-react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Checkbox from "src/components/checkbox/Checkbox";
import { FormGroup } from "src/components/input-set/FormGroup";
import { Stage } from "../../BusinessRewards.types";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { FormValues } from "./BusinessRewardsAccountActivity.types";
import { parseBcaConfiguration } from "./BusinessRewardsAccountActivity.utils";

export const BusinessRewardsAccountActivity = () => {
  const {
    updateStepperConfig,
    setCurrentStage,
    businessRewardsData,
    setBusinessRewardsData,
    setShowGenericError,
  } = useBusinessRewardsContext();

  const { MONTHLY_PAYMENTS_IN, SOURCE_OF_FUNDS, PURPOSE_OF_ACCOUNT } = parseBcaConfiguration();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { ...businessRewardsData?.businessRewardsApplicationSections.accountSection },
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    setShowGenericError(false);
    const newData = { ...businessRewardsData };
    newData.businessRewardsApplicationSections.accountSection = values;

    setBusinessRewardsData(newData);
    setCurrentStage(Stage.DOCUMENT);
    updateStepperConfig([
      { stage: Stage.ACCOUNT, value: { status: StepStatus.COMPLETE } },
      {
        stage: Stage.DOCUMENT,
        value: { status: StepStatus.INCOMPLETE },
      },
    ]);
  };

  return (
    <>
      <Heading color="$text-01" size="h1" as="h1" mb="1.6rem" mt="6.4rem">
        Account activity
      </Heading>
      <Text as="p" mb="6.4rem" color="$text-03" textStyle="body-03">
        As part of our ongoing commitment to protecting our Customers money, it is important that we
        know how you expect to use your new account.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormControl mb="3.2rem" isRequired isInvalid={!!errors.purposeOfAccount}>
          <FormLabel>Purpose of account</FormLabel>
          <FormHelperText mb="1.2rem">{PURPOSE_OF_ACCOUNT.description}</FormHelperText>
          <Controller
            control={control}
            name="purposeOfAccount"
            rules={{ required: "Please select at least one option" }}
            render={({ field: { ref, ...rest } }) => (
              <CheckboxGroup {...rest}>
                {PURPOSE_OF_ACCOUNT.options.map(({ id, name }) => {
                  const stringId = String(id);
                  return (
                    <Checkbox ref={ref} key={stringId} value={stringId}>
                      {name}
                    </Checkbox>
                  );
                })}
              </CheckboxGroup>
            )}
          />
          <FormErrorMessage mt="1.2rem" mb="2.4rm">
            <>{errors?.purposeOfAccount && errors?.purposeOfAccount?.message}</>
          </FormErrorMessage>
        </FormControl>
        <FormGroup
          label="Monthly payments in"
          isRequired
          info={MONTHLY_PAYMENTS_IN.description}
          error={errors.monthlyPaymentsIn?.message}
          mb="3.2rem"
        >
          <SimpleSelect
            placeholder="Please select"
            {...register("monthlyPaymentsIn", {
              required: "Please select an amount",
            })}
          >
            {MONTHLY_PAYMENTS_IN.options.map((option, index: number) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </SimpleSelect>
        </FormGroup>
        <FormControl isRequired isInvalid={!!errors.sourceOfFunds}>
          <FormLabel>Source of funds</FormLabel>
          <FormHelperText mb="1.2rem">{SOURCE_OF_FUNDS.description}</FormHelperText>
          <Controller
            control={control}
            name="sourceOfFunds"
            rules={{ required: "Please select at least one option" }}
            render={({ field: { ref, ...rest } }) => (
              <CheckboxGroup {...rest}>
                {SOURCE_OF_FUNDS.options.map(({ id, name }) => {
                  const stringId = String(id);
                  return (
                    <Checkbox ref={ref} key={stringId} value={stringId}>
                      {name}
                    </Checkbox>
                  );
                })}
              </CheckboxGroup>
            )}
          />
          <FormErrorMessage mt="1.2rem" mb="2.4rm">
            <>{errors?.sourceOfFunds && errors?.sourceOfFunds?.message}</>
          </FormErrorMessage>
        </FormControl>
        <Button
          // isLoading={false} // reminder for api integration work
          loadingText="Save and continue"
          spinnerPlacement="end"
          type="submit"
          float="right"
          padding="2.4rem 3.2rem"
          mt="8rem"
          rightIcon={<ChevronRightIcon />}
        >
          Save and continue
        </Button>
      </form>
    </>
  );
};
