import { StoreContextType } from "src/core/store/reference/reference.types";
import { BusinessAddressType, CompanyFormValues } from "./Company.types";

export const defaultBusinessDetails = {
  businessName: "",
  companyHouseNumber: "",
  businessStartDate: "",
  businessExplanation: "",
  numberOfEmployees: "",
  expectedAnnualTurnover: "",
  websiteUrl: "",
  businessEntityType: "",
  businessSicCodes: [],
  businessNature: "",
  internationalTradingCountryIsoCodes: [],
  taxIdentifications: [
    {
      taxCountryCode: "GB",
      number: "NA",
    },
  ],
  source: null,
  addresses: [] as BusinessAddressType[],
  countryOfRegistration: "",
  marketingPreferences: null,
  phone: null,
  emailAddress: null,
  businessDetailsComplete: null,
  profileCreationDate: null,
  customerSourceReference: null,
  allicaRMName: null,
  allicaRMPhone: null,
  businessMainBank: null,
  auditorName: null,
  allicaGroupId: null,
  annualReviewDate: null,
  crmReference: null,
  businessTradingName: null,
  allicaRiskRating: null,
  riskCheckResults: [],
  shareHolding: null,
  shareHoldingPercentage: null,
  natureOfControl: null,
  nodeId: "",
  nodeType: "",
  parentNodeId: null,
  connectedIndividuals: [],
  connectedBusinesses: [],
  fromCompaniesHouse: true,
  businessBusinessRelations: null,
  reviewComments: null,
  companyStatus: "",
  companyStatusDetail: null,
  role: null,
};
export const defaultCompaniesHouseData = {
  businessProfile: { ...defaultBusinessDetails },
};
export const formatDateForBackend = (date: string) => {
  return date?.split("/")?.reverse()?.join("-") ?? "";
};

export const formatDateToDisplay = (date: string) => {
  return date?.split("-")?.reverse()?.join("/") ?? "";
};

export const convertAddressObjToString = (obj: Object) => {
  const objValuesArr = Object.values(obj);
  return objValuesArr.filter((el) => el !== "")?.join(", ");
};

export const getUpdatedFrontendFields = (
  data: CompanyFormValues,
  referenceData: StoreContextType
) => {
  const { taxIdentifications, internationalTradingCountryIsoCodes, businessStartDate } = data;
  const registeredAddress = data?.addresses?.find(
    (add) => add?.addressType === "REGISTERED_OFFICE_ADDRESS"
  ) as BusinessAddressType;

  let sisCodeString = "";
  if (Array?.isArray(data?.businessSicCodes)) {
    data?.businessSicCodes?.forEach((ele: any) => {
      const data = referenceData?.sicCodes?.find((d) => d?.name === `${ele}`);
      sisCodeString += `${data?.name} - ${data?.description} \n`;
    });
  }

  let registeredAddressDetails = {};
  if (registeredAddress) {
    const { nodeId, nodeType, country, addressStatus, addressType, ...otherData } =
      registeredAddress;
    registeredAddressDetails = otherData;
  }

  const countries =
    internationalTradingCountryIsoCodes
      ?.map((c: string) => ({ value: c }))
      ?.filter((country) => country?.value !== "GB") || [];

  const newTaxIdentifications = taxIdentifications?.filter((c: any) => c?.taxCountryCode !== "GB");

  return {
    businessStartDate: formatDateToDisplay(businessStartDate),
    countries: countries,
    newTaxIdentifications: newTaxIdentifications,
    sisCodeString: sisCodeString,
    registeredAddress: convertAddressObjToString(registeredAddressDetails),
    confirmation: false,
  };
};

export const getBackendDataStructure = (data: CompanyFormValues) => {
  const { companiesHouseResponse, ...businessDetails } = data;
  const { countries, taxResidencies, registeredAddress, confirmation, ...otherDetails } =
    businessDetails;

  const internationalTradingCountryIsoCodes = countries
    ?.map((c) => c?.value)
    ?.filter((country) => country !== "GB");

  const taxIdentifications = taxResidencies?.filter(
    (c) => !["GB", ""]?.includes(c?.taxCountryCode)
  );
  const newBusinessSicCodes = companiesHouseResponse?.businessProfile?.businessSicCodes;

  const newAddresses: BusinessAddressType[] = otherDetails?.addresses?.map((add) => {
    const { searchAddress, ...otherAdd } = add;
    return {
      ...otherAdd,
      addressStatus: "ACTIVE",
      nodeType: "A",
    };
  });

  const updatedData = {
    companiesHouseResponse: companiesHouseResponse,
    ...otherDetails,
    businessSicCodes: newBusinessSicCodes,
    addresses: newAddresses,
    internationalTradingCountryIsoCodes: ["GB", ...internationalTradingCountryIsoCodes],
    taxIdentifications: [{ taxCountryCode: "GB", number: "NA" }, ...taxIdentifications],
    businessStartDate: formatDateForBackend(otherDetails?.businessStartDate),
  };

  return updatedData;
};
