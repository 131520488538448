import { useEffect, useState } from "react";
import { Heading, getHeadingStyle } from "@allica/ui-react";
import { FormGroup } from "src/components/input-set/FormGroup";
import ManageAddress from "src/components/address/manage-address/ManageAddress";
import { useFormContext } from "react-hook-form";
import { CompanyFormValues } from "../Company.types";
import { convertAddressObjToString } from "../Company.utils";
import { BaseTextArea } from "src/components/text-area/TextArea";

const addressMapper: any = {
  REGISTERED_OFFICE_ADDRESS: "registered office address",
  CORRESPONDENCE_ADDRESS: "correspondence address",
  TRADING_ADDRESS: "trading address",
};
const CompanyAddresses = () => {
  const { getValues, register } = useFormContext<CompanyFormValues>();
  const addresses = getValues("addresses");

  useEffect(() => {
    const defaultAddressOptions = addresses?.map((add: any) => ({
      addressType: add?.addressType,
      usedAddressType: "",
      text: addressMapper[add?.addressType],
      helperText: convertAddressObjToString({
        ...add,
        addressType: "",
        nodeId: "",
        nodeType: "",
        country: "",
        addressStatus: "",
      }),
      addressDetails: { ...add, county: add?.county || "", nodeId: "", nodeType: "A" },
    }));
    setAddressOptions(defaultAddressOptions);
  }, [addresses]);

  const [addressOptions, setAddressOptions] = useState<any>([]);

  return (
    <>
      <Heading sx={getHeadingStyle("heading-05")} color="$text-01" mb="1.6rem" mt="8rem">
        Addresses
      </Heading>
      <FormGroup label="Registered office address" mb="3.2rem" error={""} isRequired>
        <BaseTextArea
          isInvalid={false}
          isDisabled
          sx={{ WebkitTextFillColor: "#1A3660" }}
          {...register("registeredAddress", {
            required: "please provide a valid registered address",
          })}
        />
      </FormGroup>

      {addressOptions?.length > 0 && (
        <>
          <ManageAddress
            addressType={"TRADING_ADDRESS"}
            addressTypeLabel={"trading address"}
            addressHelperText={"Start typing your address or postcode"}
            addressOptions={addressOptions}
            setAddressOptions={setAddressOptions}
            fieldRootPath={`addresses.1`}
            addressNumber={1}
          />
          <ManageAddress
            addressType={"CORRESPONDENCE_ADDRESS"}
            addressTypeLabel={"correspondence address"}
            addressHelperText={"Start typing your address or postcode"}
            addressOptions={addressOptions}
            setAddressOptions={setAddressOptions}
            fieldRootPath={`addresses.2`}
            addressNumber={2}
          />
        </>
      )}
    </>
  );
};

export default CompanyAddresses;
