export const getDefaultDescription = (name: string, options: any) => {
  const item = options.filter((item: any) => item.name === name);
  return item.length ? item[0].description : "";
};

export const defaultApplicantSectionData = {
  title: "",
  firstName: "",
  middleName: null,
  lastName: "",
  gender: null,
  dateOfBirth: {
    day: "",
    month: "",
    year: "",
  },
  countryOfBirth: "",
  nationalities: [],
  addresses: [
    {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      postCode: "",
      countryIsoCode: "",
      addressType: "",
      addressStatus: "",
      nodeId: null,
      nodeType: null,
      country: null,
    },
  ],
  taxIdentifications: [],
  phone: "+44 4567788894",
  emailAddress: "rama.gunapati@allica.com",
  preferredName: null,
  vulnerability: null,
  annualReviewDate: null,
  profileCreationDate: null,
  crmReference: null,
  customerSourceReference: null,
  personalDetailsComplete: null,
  evidenceStatus: null,
  source: null,
  marketingPreferences: null,
  allicaGroupId: null,
  allicaRMName: null,
  allicaRMPhone: null,
  expectedAnnualTurnover: null,
  allicaRiskRating: null,
  shareHolding: "",
  shareHoldingPercentage: "",
  natureOfControl: [],
  role: "DIRECTOR",
  kind: null,
  riskCheckResults: [],
  nodeId: "2",
  nodeType: "I",
  parentNodeId: "1",
  dateOfBirthSCV: "",
  onlineBanking: true,
  businessIndividualRelations: [],
  reviewComments: null,
  amountToDeposit: null,
  productReference: null,
  documentSection: null,
  accountSection: null,
  productIssueId: null,
  internationalTradingCountryIsoCodes: null,
  isMainContact: true,
};
