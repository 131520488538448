import Joi from "joi";
import { splitPhoneNumber } from "src/components/input/international-phone/utils/InternationalPhone.utils";

export const checkPhoneNumber = (value: string, helper: Joi.CustomHelpers) => {
  const { phoneNumber, countryCode } = splitPhoneNumber(value);
  if (countryCode === "") {
    return helper.message({ custom: "Please select country code" });
  } else if (!phoneNumber.match(/^[0-9]{9,14}$/)) {
    return helper.message({ custom: "Please enter a valid mobile number" });
  }
  return value;
};

export const signUpSchema = Joi.object({
  emailAddress: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Please enter a valid email address",
      "string.email": "Please enter a valid email address",
    }),
  reTypeEmail: Joi.string()
    .label("Re-Type Email")
    .valid(Joi.ref("emailAddress"))
    .messages({ "any.only": "The two email address do not match. Please check and try again" }),
  password: Joi.string()
    .$.regex(
      /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()+\-_=[\]{};:|,.<>/?]){1,}).{10,}$/
    )
    .rule({ message: "Please enter a valid password" })
    .messages({ "string.empty": "Please enter a valid password" }),
  reTypePassword: Joi.string()
    .label("Re-Type Password")
    .equal(Joi.ref("password"))
    .messages({ "any.only": "The two passwords do not match. Please check and try again" }),
  phoneNumber: Joi.string().custom(checkPhoneNumber).messages({
    "string.empty": "Please enter a phone number",
  }),
});
