import { Button, ChevronRightIcon, Heading, StepStatus, Text } from "@allica/ui-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormGroup } from "src/components/input-set/FormGroup";
import { CurrencyInput } from "src/components/input/currency/CurrencyInput";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { DepositFormValues } from "../../../../shared/deposit/Deposit.types";
import { Stage } from "../../BusinessRewards.types";
import { DepositsAPI } from "src/core/service";
import {
  ApplicationStatusResponse,
  DepositErrorFields,
} from "src/core/service/deposits-api/DepositsApi.types";
import { useEffect } from "react";

export const BusinessRewardsDeposit = () => {
  const {
    setCurrentStage,
    updateStepperConfig,
    businessRewardsData,
    setBusinessRewardsData,
    setShowGenericError,
    stepperConfig,
  } = useBusinessRewardsContext();

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
    setError,
  } = useForm<DepositFormValues>();

  const { status, request, error } = DepositsAPI<ApplicationStatusResponse, DepositErrorFields>(
    `applications/businesses/${businessRewardsData?.applicationID}/deposit`,
  );

  const onSubmit: SubmitHandler<DepositFormValues> = () => {
    setShowGenericError(false);
    const depositAmount = getValues("depositAmount");
    const payload = { depositAmount, depositDuration: "NA" };
    request({
      method: "PATCH",
      body: JSON.stringify(payload),
    });
  };

  useEffect(() => {
    if (status.success) {
      const newData = { ...businessRewardsData };
      const depositAmount = getValues("depositAmount");

      const values = {
        ...newData.businessRewardsApplicationSections.depositSection!, // remove ! after FeatureFlag.BRA_PHASE_TWO
        depositAmount,
      };
      newData.businessRewardsApplicationSections.depositSection = values;
      setBusinessRewardsData(newData);

      const newState = [{ stage: Stage.DEPOSIT, value: { status: StepStatus.COMPLETE } }];
      // only set incomplete if the page has not yet been accessed/completed
      if (stepperConfig[Stage.ACCOUNT].status === StepStatus.INACTIVE) {
        newState.push({ stage: Stage.ACCOUNT, value: { status: StepStatus.INCOMPLETE } });
      }

      setCurrentStage(Stage.ACCOUNT);
      updateStepperConfig(newState);
    }
  }, [status.success]);

  useEffect(() => {
    if (status.error) {
      if (error.code === "VALIDATION_ERROR") {
        error?.errors?.forEach((data) => {
          const { field, reason } = data;
          setError(field, { message: reason });
        });
      } else setShowGenericError(true);
    }
  }, [status.error]);

  return (
    <>
      <Heading color="$text-01" size="h1" as="h1" mb="24px" mt="64px">
        Deposit amount
      </Heading>
      <Text color="$text-03" textStyle="body-03" mb={"6.4rem"}>
        How much will you deposit into the new account as an initial payment
      </Text>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup
          label="How much would you like to deposit?"
          info="This account is designed for businesses keeping a balance of £50,000 or more in their account. Lower balances may incur reduced interest on the Savings Pot or fees"
          error={errors?.depositAmount?.message}
          isRequired
        >
          <CurrencyInput
            w="23.8rem"
            value={
              businessRewardsData?.businessRewardsApplicationSections?.depositSection?.depositAmount
            }
            {...register("depositAmount", {
              required: "Please add an expected deposit amount",
              min: {
                value: 0.01,
                message: "Please add an expected deposit amount",
              },
              setValueAs: (value) => Number(value.replace(/,|£/g, "")),
            })}
            isInvalid={!!errors?.depositAmount}
          />
        </FormGroup>

        <Button
          isLoading={status.loading}
          loadingText="Save and continue"
          spinnerPlacement="end"
          type="submit"
          float="right"
          mt="8rem"
          padding="2.4rem 3.2rem"
          rightIcon={<ChevronRightIcon />}
        >
          Save and continue
        </Button>
      </form>
    </>
  );
};
