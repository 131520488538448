import { Stack } from "@chakra-ui/react";
import { ChangeEvent, forwardRef, useEffect, useState } from "react";
import { CheckList } from "./checklist/CheckList";
import { PasswordInput } from "../password/PasswordInput";
import { FormGroup } from "../../input-set/FormGroup";
import { PasswordCheckType, PasswordProps } from "./PasswordWithCheckList.types";

export const checkPasswordStrength = (value: string): PasswordCheckType => {
  const passwordCheck: PasswordCheckType = {
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecChar: false,
    has10Char: false,
  };

  passwordCheck.has10Char = value.length >= 10;
  passwordCheck.hasUpperCase = !!value.match(/[A-Z]/g);
  passwordCheck.hasLowerCase = !!value.match(/[a-z]/g);
  passwordCheck.hasNumber = !!value.match(/^(?=.*\d)/g);
  passwordCheck.hasSpecChar = !!value.match(/(?=.*?[!@#$%^&*()+\-_=[\]{};:|,.<>/?])/g);

  return passwordCheck;
};

export const PasswordWithCheckList = forwardRef<HTMLInputElement, PasswordProps>(
  ({ onChange, errorMsg, ...rest }, ref) => {
    const [passwordValidation, setPasswordValidation] = useState<PasswordCheckType>({
      hasLowerCase: false,
      hasUpperCase: false,
      hasNumber: false,
      hasSpecChar: false,
      has10Char: false,
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e);
      setPasswordValidation(checkPasswordStrength(e.target.value));
    };

    useEffect(() => {
      const newValue = JSON.stringify(checkPasswordStrength(rest?.value?.toString() ?? ""));
      const previousValue = JSON.stringify(passwordValidation);
      if (newValue !== previousValue) {
        setPasswordValidation(checkPasswordStrength(rest?.value?.toString() ?? ""));
      }
    }, [rest?.value]);

    return (
      <>
        <FormGroup label="Password" error={errorMsg} isRequired>
          <Stack spacing={2}>
            <CheckList
              text={"Contains 1 lower case letter"}
              done={passwordValidation.hasLowerCase}
            />
            <CheckList
              text={"Contains 1 upper case letter"}
              done={passwordValidation.hasUpperCase}
            />
            <CheckList text={"Contains 1 number"} done={passwordValidation.hasNumber} />
            <CheckList
              text={"Contains 1 special character"}
              done={passwordValidation.hasSpecChar}
            />
            <CheckList text={"Minimum of 10 characters"} done={passwordValidation.has10Char} />
            <PasswordInput
              name="password"
              isInvalid={!!errorMsg}
              onChange={handleChange}
              ref={ref}
              {...rest}
            />
          </Stack>
        </FormGroup>
      </>
    );
  }
);
