import { ReSendSecurityCodeProps } from "./ReSendSecurityCode.types";
import { Text } from "@allica/ui-react";

import { HStack, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RepeatIcon } from "src/components/icon";
import { DepositsAPI } from "src/core/service";
import environment from "src/environments/environment";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { appInsights } from "src/core/app/ApplicationInsights";
import { BusinessSavingDataTypes } from "src/pages/business-savings/BusinessSavings.types";
import { PersonalSavingDataTypes } from "src/pages/personal-savings/PersonalSavings.types";
import { BusinessRewardsDataTypes } from "src/pages/business-rewards/BusinessRewards.types";

const ReSendSecurityCode = <DataType extends PersonalSavingDataTypes | BusinessSavingDataTypes | BusinessRewardsDataTypes>({
  setNoOfSecurityCodeSent,
  noOfSecurityCodeSent,
  applicationData,
  setApplicationData,
  setShowGenericError,
}: ReSendSecurityCodeProps<DataType>) => {
  const [requestForSecurityCodeTimer, setRequestForSecurityCodeTimer] = useState(
    environment.ping.resendTimer
  );

  const { status: reSendReqStatus, request: reSendRequest } = DepositsAPI(
    `applications/${applicationData?.applicationID}/resend-otp`
  );

  const requestForSecurityCode = () => {
    if (isFeatureActive(FeatureFlag.EVENT_INSIGHTS)) {
      appInsights.trackEvent({ name: `resend-security-code` });
    }
    setShowGenericError(false);
    reSendRequest({
      method: "POST",
    });
  };

  useEffect(() => {
    if (requestForSecurityCodeTimer === environment.ping.resendTimer) {
      let count = requestForSecurityCodeTimer,
        timer = setInterval(function () {
          count--;
          setRequestForSecurityCodeTimer(count);
          if (count === 0) clearInterval(timer);
        }, 1000);
    }
  }, [requestForSecurityCodeTimer]);

  useEffect(() => {
    if (reSendReqStatus.success) {
      setRequestForSecurityCodeTimer(environment.ping.resendTimer);
      setNoOfSecurityCodeSent(noOfSecurityCodeSent + 1);

      const newData = { ...applicationData };
      const newValue = {
        noOfSecurityCodeSent: noOfSecurityCodeSent + 1,
      };
      if ("businessApplicationSections" in newData)
        newData.businessApplicationSections.verifySection = newValue;
      else if ("businessRewardsApplicationSections" in newData)
        newData.businessRewardsApplicationSections.verifySection = newValue;
      else newData.individualApplicationSections.verifySection = newValue;
      setApplicationData(newData);
    }
  }, [reSendReqStatus.success]);

  useEffect(() => {
    reSendReqStatus.error && setShowGenericError(true);
  }, [reSendReqStatus.error]);

  return (
    <HStack color={requestForSecurityCodeTimer === 0 ? "$interactive-01" : "gray1"} spacing="1rem">
      <RepeatIcon boxSize="2.4rem" spin={reSendReqStatus.loading} />
      {requestForSecurityCodeTimer === 0 ? (
        <Link onClick={requestForSecurityCode}>Re-send security code</Link>
      ) : (
        <Text>Re-send security code in {requestForSecurityCodeTimer} second</Text>
      )}
    </HStack>
  );
};

export default ReSendSecurityCode;
