import { useParams } from "react-router-dom";
import {
  Button,
  ChevronRightIcon,
  getTextStyle,
  Heading,
  Text,
  FormControl,
  FormErrorMessage,
  StepStatus,
  PageLayout,
} from "@allica/ui-react";
import { Box, Card, Flex, GridItem, Link, ListItem, UnorderedList } from "@chakra-ui/react";
import Checkbox from "../../../../components/checkbox/Checkbox";
import { LinkIcon } from "src/components/icon";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import environment from "src/environments/environment";
import { usePersonalContext } from "../../context/PersonalContext";
import { Stage } from "../../PersonalSavings.types";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { appInsights } from "src/core/app/ApplicationInsights";
import { PrivacyFormValues } from "src/shared/privacy/Privacy.types";

import style from "src/shared/privacy/Privacy.module.css";

const Privacy = () => {
  const { setCurrentStage, setStepperConfig, stepperConfig, personalSavingData } =
    usePersonalContext();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PrivacyFormValues>();

  const params = useParams();

  const onSubmit: SubmitHandler<PrivacyFormValues> = () => {
    if (isFeatureActive(FeatureFlag.EVENT_INSIGHTS)) {
      appInsights.trackEvent({ name: `personal-deposits-${params.product}-started` });
    }
    const newState = { ...stepperConfig };
    if (
      personalSavingData?.individualApplicationSections?.signUpSection?.emailAddress?.length === 0
    ) {
      newState[Stage.SIGN_UP].status = StepStatus.INCOMPLETE;
      setStepperConfig(newState);
    } else {
      newState[Stage.SIGN_UP].status = StepStatus.COMPLETE;
      setStepperConfig(newState);
    }
    setCurrentStage(Stage.SIGN_UP);
  };

  return (
    <>
      <Box className={style.privacyHeader}>
        <PageLayout
          paddingBottom={{ base: "4rem", sm: "2.4rem", md: "4rem" }}
          paddingTop={{ base: "27.1rem", sm: "40rem", md: "4.8rem" }}
        >
          <GridItem
            colStart={{ base: 1, sm: 1, md: 1, lg: 1 }}
            colSpan={{ base: 4, sm: 8, md: 5, lg: 6 }}
            padding={0}
          >
            <Heading
              color={{ base: "$interactive-02", md: "$ui-02" }}
              textAlign={{ base: "center", md: "left" }}
              fontSize={{ base: "4rem", md: "4.8rem" }}
              lineHeight={{ base: "4.8rem", md: "5.6rem" }}
            >
              Welcome to a better personal savings account
            </Heading>
          </GridItem>
        </PageLayout>
      </Box>
      <Box
        className={style.privacyImage}
        backgroundPosition={{ md: "80% bottom", lg: "90% bottom" }}
      >
        <PageLayout paddingBottom="6.4rem" paddingTop="4rem">
          <GridItem
            as="section"
            colStart={{ base: 1, sm: 1, md: 1, lg: 1 }}
            colSpan={{ base: 4, sm: 8, md: 5, lg: 6 }}
          >
            <Heading
              color="mango.500"
              fontSize={{ base: "2rem", md: "2.4rem" }}
              lineHeight={{ base: "2.8rem", md: "3.2rem" }}
            >
              What you’ll need to apply
            </Heading>

            <Card
              p="2.4rem"
              mt="2.4rem"
              boxShadow="none"
              borderRadius="0.4rem"
              bg={"$background-02"}
              sx={getTextStyle("body-03")}
              color="$text-02"
            >
              <UnorderedList>
                <ListItem>
                  <Text as="span" sx={getTextStyle("medium-03")}>
                    Bank details
                  </Text>{" "}
                  of the account you’ll transfer funds from.
                </ListItem>
                <ListItem>
                  <Text as="span" sx={getTextStyle("medium-03")}>
                    Tax identification numbers
                  </Text>{" "}
                  if you pay tax outside the UK.
                </ListItem>
              </UnorderedList>
            </Card>

            <Heading
              color="$text-01"
              fontSize={{ base: "2rem", md: "2.4rem" }}
              lineHeight={{ base: "2.8rem", md: "3.2rem" }}
              mt="6.4rem"
            >
              Privacy policy
            </Heading>

            <Text
              mb="2.4rem"
              mt="2.4rem"
              fontSize={{ base: "1.4rem", md: "1.6rem" }}
              lineHeight={{ base: "2rem", md: "2.4rem" }}
            >
              We respect your privacy and are committed to protecting your personal data. This
              privacy policy will inform you what data we collect, how we collect it, how we use it,
              who we share it with and explain your legal rights.
              <br />
              <br />
              The personal information we collect from you will be shared with fraud prevention
              agencies who will use it to prevent fraud and money-laundering and to verify your
              identity. If fraud is detected, you could be refused certain services, finance or
              employment.
            </Text>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <LinkIcon mr="1.75rem" boxSize="2.4rem" />
                <Link
                  color="$link-01"
                  fontSize={{ base: "1.4rem", md: "1.6rem" }}
                  lineHeight={{ base: "2rem", md: "2.4rem" }}
                  href={environment.ui.allicaPortal + "/privacy-policy"}
                  target="_blank"
                >
                  Allica Privacy Policy
                </Link>
              </Box>
              <Text
                mb="2.4rem"
                mt="2.4rem"
                fontSize={{ base: "1.4rem", md: "1.6rem" }}
                lineHeight={{ base: "2rem", md: "2.4rem" }}
              >
                Once you provide consent you may change your mind and withdraw your consent at any
                time by contacting us at customerservices@allica.bank but that will not affect the
                lawfulness of any processing carried out before you withdrew your consent.
              </Text>

              <FormControl isInvalid={!!errors.privacyAccepted}>
                <Controller
                  control={control}
                  name="privacyAccepted"
                  render={({ field: { ref, ...rest } }) => (
                    <Checkbox {...rest}>I accept Allica's Privacy Policy</Checkbox>
                  )}
                  rules={{
                    required:
                      "Please confirm you have read the Privacy Policy and accept the terms",
                  }}
                />
                <FormErrorMessage mt="1.2rem" mb="2.4rem">
                  <>{errors.privacyAccepted && errors.privacyAccepted.message}</>
                </FormErrorMessage>
              </FormControl>
              <Flex
                direction={{ base: "column-reverse", xl: "row" }}
                justifyContent={{ base: "center", xl: "flex-end" }}
                mt="6.4rem"
              >
                <Link
                  href={environment.ui.onlineBanking}
                  color="$link-01"
                  sx={getTextStyle("body-03")}
                  alignSelf={{ base: "center", lg: "left" }}
                  mt={{ base: "5rem", xl: "unset" }}
                  mr={{ xl: "5rem" }}
                >
                  Already a customer?
                </Link>

                <Button
                  loadingText="Save and continue"
                  spinnerPlacement="end"
                  type="submit"
                  padding="2rem 2.4rem"
                  alignSelf={{ base: "center", md: "left" }}
                  rightIcon={<ChevronRightIcon />}
                >
                  Begin application
                </Button>
              </Flex>
            </form>
          </GridItem>
        </PageLayout>
      </Box>
    </>
  );
};

export default Privacy;
